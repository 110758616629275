import React from "react";
import type {AppProps} from 'next/app'
import 'styles/globals.css'

import '@App/components/common/DayPicker/DayPicker.css'

// i18n
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "../graphql/client";
import {TranslationsProvider } from '@eo-locale/react';

import {arval_de, dbconnect_de, repareo_de} from "../resources/locales";

const locales = [
    {
        language: 'repareo_de',
        messages: {
            ...repareo_de
        }
    },
    {
        language: 'dbconnect_de',
        messages: {
            ...dbconnect_de
        }
    },
    {
        language: 'arval_de',
        messages: {
            ...arval_de
        }
    }
];

const siteLanguage = 'repareo_de';

function MyApp({Component, pageProps}: AppProps) {
    return (
        <ApolloProvider client={apolloClient}>
                <TranslationsProvider language={siteLanguage} locales={locales}>
                    <Component {...pageProps} />
                </TranslationsProvider>
        </ApolloProvider>
    );
}

export default MyApp
