export const de_DE_formal = {
    'profileDetails_days_monday' : 'Montag',
    'profileDetails_days_tuesday': 'Dienstag',
    'profileDetails_days_wednesday': 'Mittwoch',
    'profileDetails_days_thursday': 'Donnerstag',
    'profileDetails_days_friday': 'Freitag',
    'profileDetails_days_saturday': 'Samstag',
    'profileDetails_days_sunday': 'Sonntag',

    translation: {

        'Waehlen Sie einen Service.': 'Wählen Sie einen Service.',
        'Waehlen Sie Ihren Wunschort.': 'Wählen Sie Ihren Wunschort.',
        'Werkstatt suchen': 'Werkstatt suchen',
        benefits: {
            'title': 'Ihre Vorteile',
            'bullet-point-1': 'Freie Termine direkt online buchen',
            'bullet-point-2': 'Die besten Werkstattpartner auf einem Blick – bewertet nach den Qualitätsstandards von {{organization}}',
            'bullet-point-3': 'Exklusive Zusatzservices wie Hol- und Bring, Ersatzfahrzeug oder Autowäsche hinzufügen',
        },
        contactForm: {
            'headline': 'Fast geschafft!',
            'sub-headline': 'Wie erreichen wir Sie?',
            'number-plate-label': 'Wie lautet Ihr KFZ-Kennzeichen?*',
            'message-label': 'Möchten Sie dem Werkstatt-Team noch etwas mitteilen?',
            'slot-out-of-stock': 'Ihr gewünschter Termin ist leider inzwischen vergriffen. Bitte suchen Sie sich einen neuen Termin aus.',
            'submit': 'Terminbuchung abschließen',
            'mandatory-field-notice': '*Pflichtangaben',
            placeholders: {
                'first-name': 'Vorname*',
                'last-name': 'Nachname*',
                'email': 'E-Mail*',
                'phone': 'Telefonnummer für Rückfragen*',
                'message': 'Anmerkungen (optional)'
            },
        },
        contact: {
            'title': 'Kontakt',
            'text': 'Haben Sie Fragen zur Buchung? Paul steht Ihnen zur Seite.',
            'phone': 'Telefon',
            'reachability': '(werktags 8-20 Uhr)',
            'email': 'E-Mail',
        },
        keyNumber: {
            'headline': 'Geben Sie hier Ihre Fahrzeugschein-Daten ein.',
            'submit': 'Bestätigen',
            'to-brand': 'oder gehen Sie zurück zur',
            'brand-selection': 'Markenauswahl',
        },
        keyNumberApproval: {
            'headline': 'Ist das Ihr Fahrzeug?',
            'error': 'Wir konnten leider kein passendes Fahrzeug finden. Bitte nutzen Sie die Marken- und Modellauswahl.',
            'no-vehicle-found': 'Kein Fahrzeug gefunden',
            'no-vehicle-found-tip': 'Bitte korrigieren Sie Ihre Angaben oder benutzen Sie die Markenauswahl.'
        },
        location: {
            'headline': 'Für welche Region sollen die Kosten berechnet werden?',
            'subheadline': 'Stundensätze variieren stark nach Region, daher fragen wir die Postleitzahl ab.',
            'submit': 'Weiter',
        },
        brand: {
            'headline': 'Welche Marke fahren Sie?',
            'skip-steps': 'oder überspringen Sie die ersten 3 Schritte mit den Daten aus Ihrem ',
            'vehicle-registration': 'Fahrzeugschein',
        },
        brandList: {
            'search-brand': 'Suchen Sie hier nach Ihrer Marke',
            'important-brands': 'Häufige Marken',
            'show-all-brands': 'Alle Marken anzeigen',
        },
        generation: {
            'choose-model': 'Wählen Sie Ihr Modell',
            'skip-steps': 'oder überspringen Sie die ersten 3 Schritte mit den Daten aus Ihrem ',
            'vehicle-registration': 'Fahrzeugschein',
        },
        generationList: {
            'search-generation': 'Suchen Sie hier nach Ihrem Modell',
        },
        bodies: {
            'headline': 'Bauform wählen',
            'subheadline': 'Sie wissen nicht genau, was Sie hier anklicken sollen? Dann lassen Sie die Felder einfach frei. Wir\n' +
                '                        kalkulieren dann mit dem Basismodell. Die Werkstatt wird Ihnen auf Basis Ihres Fahrzeuges einen\n' +
                '                        Kostenvoranschlag erstellen.',
            'submit': 'Weiter',
        },
        gearbox: {
            'headline': 'Wählen Sie Ihr Getriebe:',
            'subheadline': 'Je nach Getriebe können Inspektionsintervalle unterschiedlich ausfallen.',
        },
        equipment: {
            'headline': 'Wählen Sie Ihre Ausstattungsmerkmale:',
            'subheadline': 'Sie wissen nicht genau, was Sie hier anklicken sollen? Dann lassen Sie die Felder einfach frei. Wir\n' +
                '                        kalkulieren dann mit dem Basismodell. Die Werkstatt wird Ihnen auf Basis Ihres Fahrzeuges einen\n' +
                '                        Kostenvoranschlag erstellen.',
            'submit': 'Weiter',
        },
        motorization: {
            'headline': 'Wählen Sie Ihre Motorisierung:',
            'skip-steps': 'oder überspringen Sie die ersten 3 Schritte mit den Daten aus Ihrem ',
            'vehicle-registration': 'Fahrzeugschein',
        },
        motorizationList: {
            'fuel-type': 'Kraftstoff:'
        },
        registrationDate: {
            'headline': 'Erstzulassung',
            'submit': 'Weiter',
            'description': 'Auch die Erstzulassung wird bei vielen Modellen im Serviceintervall mit einbezogen.',
        },
        mileage: {
            'headline': 'Kilometerstand wählen',
            'subheadline': 'Je nach Kilometerstand ist ein anderer Service fällig.',
            'submit': 'Weiter',
        },

        profileHeader: {
            'checked-by': 'geprüft durch {{organization}}',
            'rating': 'Weiterempfehlung',
            'no-reviews': 'Noch keine Bewertung',
            'nationwide-service-1': 'Mobiler Service (deutschlandweit)',
            'nationwide-service-2': 'Diese Werkstatt kommt zu Ihnen nach Hause'
        },
        profileBody: {
            'location-headline': 'Hier finden Sie'
        },
        booking: {
            'headline': 'Buchen Sie jetzt Ihren Termin',
            'subheadline': 'Service: {{service}}',
            'slot-taken-1': 'Schade! Da war leider jemand schneller und hat Ihren Wunschtermin am',
            'slot-taken-2': 'reserviert. Bitte wählen Sie einen anderen Termin.',
            'no-slots': 'Aktuell sind in dieser Werkstatt keine Termine für den gewählten Zeitraum verfügbar.',
            'next-free-slot': 'Nächster freier Termin:',
            'chosen-slot': 'Ausgewählter Termin:',
            'select-service': 'Bitte wählen Sie einen Service',

            'more-information-link': 'Mehr zum Terminablauf',


            moreInformation: {
                'headline': 'Wie genau läuft der Termin ab?',
                'bullet-point-1': 'Ihre Buchung geht direkt in den Kalender der Werkstatt.',
                'bullet-point-2': 'Bitte geben Sie Ihr Auto zu Beginn des Termins ab.',
                'bullet-point-3': 'Wir informieren Sie telefonisch, sobald Sie Ihr Auto abholen können.',
                'bullet-point-4': 'Sie können ihren Termin jederzeit kostenfrei absagen.',
            }
        },

        profileDetails: {
            'headline': 'Weitere Infos zur Werkstatt',
            'opening-hours': 'Öffnungszeiten',
            'description': 'Beschreibung',
            'default-description': ' Willkommen auf der repareo-Profilseite von {{garage}}. Diese Werkstatt hat\n' +
                '                            leider selbst noch keine weiteren Informationen auf repareo hinterlegt. Sie können dennoch\n' +
                '                            jederzeit über "Termin sichern" rechts oben einen Termin bei dieser Werkstatt über repareo\n' +
                '                            buchen.',

            }
        },

        checkpoint: {
            'title': 'Prüfort für die Hauptuntersuchung',
            'text': 'In dieser Werkstatt können Sie auch Ihre Hauptuntersuchung durchführen lassen. Ein Prüfer von {{checkpoint}} kommt dafür bei der Werkstatt vorbei.'
        },

        reviews: {
            rating: {
                'overall': 'Gesamtbewertung',
                'rating': 'Weiterempfehlung',
                'cleanliness': 'Sauberkeit und Erscheinungsbild',
                'quality': 'Qualität der erbrachten Leistung',
                'punctuality': 'Termintreue',
                'competence': 'Transparenz und Beratung',
                'kindness': 'Freundlichkeit der Mitarbeiter'
            },
            'customer-ratings': 'Bewertungen von repareo-Kunden',
            'show-less': 'Weniger anzeigen',
            'show-more': 'Mehr Bewertungen anzeigen',
            'more-reviews': 'Weitere Bewertungen',
        },
        brakeScope: {
            'headline': 'Welchen Umfang soll der Bremsenwechsel haben?',
            'choose-multiple': 'Mehrfachauswahl möglich',
            'front-axle': 'Vorderachse',
            'rear-axle:': 'Hinterachse',
            'submit': 'Weiter',
        },
        hint: {
            'text': 'Alle gezeigten Werkstätten sind offizielle {{organization}} Service-Partner.'
        },
        // garage list
        'Partnerwerkstaetten in Ihrer Naehe': '{{organization}} Partnerwerkstätten in Ihrer Nähe',
        'sort by': 'Sortieren nach',
        'Listenansicht': 'Listenansicht',
        'Kartenansicht': 'Kartenansicht',
        'Umgebung': 'Umgebung',
        'Verfuegbarkeit': 'Verfügbarkeit',
        'Zusatzservices': 'Zusatzservices',
        'Suche speichern': 'Suche speichern',
        'proximity-search-text': 'Geben Sie hier Ihren Standort ein, z.B. Ihre Postleitzahl oder Stadt.',
        'Datum': 'Datum',
        'Werkstattname suchen': 'Werkstattname suchen',
        'Marke': 'Marke',

    'profileBody_location_headline': 'Hier finden Sie',
    'booking_headline': 'Buchen Sie jetzt Ihren Termin',
    'booking_select_service': 'Bitte wählen Sie einen Service',
    'booking_select_vehicle': 'Fahrzeugauswahl',
    'booking_select_service_long': 'Bitte wählen Sie einen Service aus, um den Kalendar der Werkstatt zu sehen.',
    'booking_select_service_long_vehicle': 'Bitte wählen Sie einen Service und ein Fahrzeug aus, um den Kalendar der Werkstatt zu sehen.',
    'booking_slot_taken_1' : 'Schade! Da war leider jemand schneller und hat Ihren Wunschtermin am',
    'booking_slot_taken_2': 'reserviert. Bitte wählen Sie einen anderen Termin.',

    'check_search_criteria': 'Bitte überprüfen Sie ihre Suchkriterien',
    'no_results': 'Aktuell haben wir leider noch keine Werkstätten für Ihre Suchkriterien gefunden. Sie können jetzt folgendes machen:',
    'inspection_information': ' In dieser Werkstatt können Sie auch Ihre Hauptuntersuchung durchführen lassen. Ein Prüfer von {provider} kommt dafür bei der Werkstatt vorbei.',
}
