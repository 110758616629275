export const de_DE_informal = {
    'profileBody_location_headline': 'Hier findest du',

    'booking_headline': 'Buche jetzt deinen Termin',
    'booking_select_service': 'Bitte wähle einen Service',
    'booking_select_vehicle': 'Fahrzeugauswahl',
    'booking_select_service_long': 'Bitte wähle einen Service aus, um den Kalendar der Werkstatt zu sehen.',
    'booking_select_service_long_vehicle': 'Bitte wähle einen Service und ein Fahrzeug aus, um den Kalendar der Werkstatt zu sehen.',
    'booking_slot_taken_1' : 'Schade! Da war leider jemand schneller und hat deinen Wunschtermin am',
    'booking_slot_taken_2': 'reserviert. Bitte wähle einen anderen Termin.',

    'check_search_criteria': 'Bitte prüfe deine Suchkriterien',
    'no_results': 'Aktuell haben wir leider noch keine Werkstätten für deine Suchkriterien gefunden. Du kannst jetzt folgendes machen:',
    'inspection_information': ' In dieser Werkstatt kannst du auch deine Hauptuntersuchung durchführen lassen. Ein Prüfer von {provider} kommt dafür bei der Werkstatt vorbei.',
}
