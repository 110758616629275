import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {offsetLimitPagination} from "@apollo/client/utilities";
import {setContext} from "@apollo/client/link/context";

const httpLink = createHttpLink({
    uri: 'https://werkstatt.repareo.de/graphql',
    credentials: "include"
})


// // csrf_url declared in resources/react/types/index.ts
// const csrf = await fetch(window.csrf_url ?? 'https://werkstatt.repareo.de/sanctum/csrf-cookie')

const authLink = setContext((_, {headers}) => {
    //const token = (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)?.content || null
    return {
        headers: {
            ...headers,
            //"X-XSRF-TOKEN": token
        }
    }
})

export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        typePolicies: {
            GaragePaginator: {
                keyFields: [],
                fields: {
                    data: offsetLimitPagination([
                        '$id',
                        '$organization',
                        '$lat',
                        '$lon',
                        '$distance',
                        '$appointmentsFrom',
                        '$appointmentsTo',
                        '$services',
                        '$search',
                        '$sortBy',
                        '$premiumServices',
                        '$slug',
                        '$brand',
                        '$generation',
                        '$motorization',
                        '$gearbox',
                        '$body',
                        '$mileage',
                        '$registrationDate',
                        '$subRepairs',
                        '$equipments',
                    ])
                }
            }
        }
    })
})

